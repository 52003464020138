import { useEffect, useRef, useState } from 'react';
import Component from '../../components/artwork';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload from '../../library/upload.lib';

export default function Artwork() {
  const toast = useRef(null);

  const [artworks, setArtworks] = useState([]);
  const [artists, setArtists] = useState([]);
  const [artist, setArtist] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    year: '',
    price: '',
    medium: '',
    size: '',
  });

  const getArtists = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`)
      .then((response) => {
        setArtists(response.data.data);
      });
  };

  const getArtworks = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`)
      .then((response) => {
        setArtworks(response.data.data);
      });
  };

  const postArtwork = async () => {
    const array = [];

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`,
        1500
      )
        .then((s3_response) => {
          axios
            .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
              ...inputs,
              artist: artist[0],
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`,
              images: array,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Added Artwork.',
                  life: 3000,
                });
                getArtworks();
                reset();
                setImages([]);
                setThumbnail([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    await upload(
      thumbnail[0],
      `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`,
      1500
    )
      .then((s3_response) => {
        axios
          .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
            ...inputs,
            artist: artist[0],
            thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Added Artwork.',
                life: 3000,
              });
              getArtworks();
              reset();
              setImages([]);
              setThumbnail([]);
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateArtwork = async () => {
    const array = [];

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (thumbnail.length) {
        await upload(
          thumbnail[0],
          `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`,
          1500
        )
          .then((s3_response) => {
            axios
              .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
                ...inputs,
                artist: artist[0],
                thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`,
                images: array,
              })
              .then((response) => {
                if (response.data.status === 200) {
                  toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Updated Artwork.',
                    life: 3000,
                  });
                  getArtworks();
                  reset();
                  setImages([]);
                  setThumbnail([]);
                }
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
            ...inputs,
            artist: artist[0],
            images: array,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Updated Artwork.',
                life: 3000,
              });
              getArtworks();
              reset();
              setImages([]);
              setThumbnail([]);
            }
          });
      }

      return;
    }

    if (thumbnail.length) {
      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}`,
        1500
      )
        .then((s3_response) => {
          axios
            .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
              ...inputs,
              artist: artist[0],
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artwork/${artist[0].name}/${inputs.title}/${s3_response.timestamp}.jpeg`,
              images: [],
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Updated Artwork.',
                  life: 3000,
                });
                getArtworks();
                reset();
                setImages([]);
                setThumbnail([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
          ...inputs,
          artist: artist[0],
          images: [],
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Artwork.',
              life: 3000,
            });
            getArtworks();
            reset();
            setImages([]);
            setThumbnail([]);
          }
        });
    }
  };

  const deleteArtwork = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Artwork.',
            life: 3000,
          });
          getArtworks();
          reset();
        }
      });
  };

  useEffect(() => {
    getArtworks();
    getArtists();
  }, []);
  return (
    <>
      <Component
        artists={artists}
        artworks={artworks}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postArtwork={postArtwork}
        updateArtwork={updateArtwork}
        deleteArtwork={deleteArtwork}
        artist={artist}
        setArtist={setArtist}
        images={images}
        setImages={setImages}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
      />
      <Toast ref={toast} />
    </>
  );
}
