import { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import Color from 'color';
import ImageUpload from '../common/ImageUpload';
import { FilterMatchMode } from 'primereact/api';

export default function Artwork({
  artists,
  artworks,
  inputs,
  onChange,
  reset,
  postArtwork,
  updateArtwork,
  deleteArtwork,
  artist,
  setArtist,
  images,
  setImages,
  thumbnail,
  setThumbnail,
}) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />

          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    );
  };

  const handleUpdate = ({ data }) => {
    setVisible(true);
    setUpdate(true);
    reset({ ...data });
    setId(data.id);
    setArtist([data.artist]);
    setImages([]);
    setThumbnail([]);
  };

  const footerContent = (
    <div>
      <Button
        label={update ? 'Delete' : 'No'}
        icon='pi pi-times'
        onClick={() => {
          if (update) {
            deleteArtwork(id);
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setArtist([]);
          setImages([]);
          setThumbnail([]);
        }}
        severity='danger'
        text
      />
      <Button
        label={update ? 'Update' : 'Yes'}
        icon='pi pi-check'
        onClick={() => {
          if (update) {
            updateArtwork();
          } else {
            postArtwork();
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setArtist([]);
          setImages([]);
          setThumbnail([]);
        }}
        autoFocus
      />
    </div>
  );

  const header = renderHeader();

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Artwork</span>

        <Button label='Add Artwork' onClick={() => setVisible(true)} />
      </div>

      <DataTable
        value={artworks}
        paginator
        rows={20}
        header={header}
        filters={filters}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={handleUpdate}
      >
        <Column field='title' header='Title' style={{ width: '25%' }} />
        <Column field='artist.name' header='Artist' style={{ width: '20%' }} />
        <Column field='year' header='Year' style={{ width: '15%' }} />
        <Column field='medium' header='Medium' style={{ width: '20%' }} />
        <Column field='size' header='Size' style={{ width: '20%' }} />
      </DataTable>
      <Dialog
        draggable={false}
        header='Artwork'
        visible={visible}
        style={{ width: '40vw', height: '70vh' }}
        onHide={() => {
          setVisible(false);
          setUpdate(false);
          reset();
          setArtist([]);
          setImages([]);
          setThumbnail([]);
        }}
        footer={footerContent}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <InputText
            placeholder='title'
            style={{ width: '70%', marginBottom: '5px' }}
            name='title'
            value={inputs.title}
            onChange={onChange}
          />
          <small
            id='username-help'
            style={{
              width: '70%',
              display: 'flex',
              margin: '0 auto 20px auto',
            }}
          >
            * 작품명에 특수 문자를 넣을 경우 파일 업로드가 되지 않습니다.
            <br />
            <br />
            만약 특수 문자를 넣어야 하는 경우 특수 문자를 뺀 상태로 파일을
            업로드 하고서
            <br />
            추후에 작품명만 다시 업데이트 하면 특수 문자를 추가 할 수 있습니다.
          </small>
          <MultiSelect
            options={artists}
            optionLabel='name'
            filter
            placeholder='Select Artist'
            maxSelectedLabels={1}
            value={artist}
            onChange={(e) => {
              if (e.target.value.length === 2) {
                setArtist([e.target.value[1]]);

                return;
              }

              setArtist(e.target.value);
            }}
            style={{ width: '70%', marginBottom: '20px' }}
          />
          <InputText
            keyfilter={'int'}
            placeholder='year (only numbers)'
            style={{ width: '70%', marginBottom: '20px' }}
            name='year'
            value={inputs.year}
            onChange={onChange}
          />
          <InputText
            keyfilter={'int'}
            placeholder='price (only numbers)'
            style={{ width: '70%', marginBottom: '20px' }}
            name='price'
            value={inputs.price}
            onChange={onChange}
          />
          <InputText
            placeholder='medium'
            style={{ width: '70%', marginBottom: '20px' }}
            name='medium'
            value={inputs.medium}
            onChange={onChange}
          />
          <InputText
            placeholder='size'
            style={{ width: '70%', marginBottom: '20px' }}
            name='size'
            value={inputs.size}
            onChange={onChange}
          />

          {update && inputs.thumbnail ? (
            <div
              style={{
                width: '70%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: Color('#adb5bd').alpha(0.1).hsl().toString(),
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '20px',
              }}
              onClick={() => window.open(inputs.thumbnail, '_blank')}
            >
              <img
                src={inputs.thumbnail}
                alt='artwork_thumbnail'
                draggable={false}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  marginRight: '10px',
                  borderRadius: '4px',
                }}
              />
              Previously uploaded file: <i>THUMBNAIL.JPEG</i>
            </div>
          ) : (
            <></>
          )}

          <ImageUpload
            title='Upload artwork thumbnail from your browser'
            images={thumbnail}
            setImages={setThumbnail}
            style={{ width: '70%', marginBottom: '20px' }}
          />

          {update && inputs.images ? (
            inputs.images.map(({ image }, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: '70%',
                    height: 'fit-content',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '4px',
                    padding: '10px',
                    backgroundColor: Color('#adb5bd')
                      .alpha(0.1)
                      .hsl()
                      .toString(),
                    fontSize: '14px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    userSelect: 'none',
                    marginBottom: '20px',
                  }}
                  onClick={() => window.open(image, '_blank')}
                >
                  <img
                    src={image}
                    alt='artwork_image'
                    draggable={false}
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      marginRight: '10px',
                      borderRadius: '4px',
                    }}
                  />
                  Previously uploaded file: <i>{index + 1}.JPEG</i>
                </div>
              );
            })
          ) : (
            <></>
          )}

          <ImageUpload
            title='Upload artwork images from your browser'
            multiple
            images={images}
            setImages={setImages}
            style={{ width: '70%' }}
          />
        </div>
      </Dialog>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
